"use client"

import styled from "styled-components";

export const MainWrapCenter = styled.div`    
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      display: flex;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
`