'use client'
import styled from 'styled-components';


export const ListVerticalContainer = styled.section`
    display: flex;
    flex: ${props => props.flex};
    
    /* flex-direction: column; */
    align-items: center;
    /* justify-content: flex-start;  */
    gap: 20px;
    width: 100%;

    flex-direction:  ${props => props.$viewMode == 'title' ? 'row' : props.$viewMode == 'list' ? 'column ' : 'column'}; 
    flex-wrap: ${props => props.$viewMode == 'title' ? 'wrap' : props.$viewMode == 'list' ? 'unset' : 'unset'}; 
    justify-content: ${props => props.$viewMode == 'title' ? 'space-between' : props.$viewMode == 'list' ? 'flex-start ' : 'flex-start'}; 

/* .title {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.list {
    flex-direction: column;
} */
`

