
import MainWrap from './Layouts/MainWrap/MainWrap';
import { MainWrapCenter } from './Layouts/MainWrapCenter/ui';
import PageTitleWrap from './Layouts/PageTitleWrap';
import { ContentHorisontalCenter, ContentHorisontalStart, ContentHorisontalSB } from './Layouts/ContentHorizontal/Styled/ContentHorizontalStyled';
import ListVertical from './Layouts/ListVertical';
import { ListVerticalStart, ListVerticalCenter } from './Layouts/ListVertical/styled/ListVerticalStyled';
import { Layout1440, Layout1200, LayoutFullWidth } from './Layouts/LayoutsSized/ui';
import EducationLayout from './Layouts/EducationLayout/EducationLayout';
import { BlueWrap, BlueWrapSBCenter } from './Layouts/BlueWrap/BlueWrap';
import ContentHorizontal from './Layouts/ContentHorizontal';
    //  ;
export {
    MainWrap,
    MainWrapCenter,
    PageTitleWrap,
    ContentHorisontalCenter,
    ContentHorisontalStart,
    ContentHorisontalSB,
    ListVertical,
    ListVerticalStart,
    ListVerticalCenter,
    Layout1440,
    Layout1200,
    LayoutFullWidth,
    EducationLayout,
    BlueWrap,
    BlueWrapSBCenter,
    ContentHorizontal
    
}