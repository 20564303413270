
// "use client"
import React from 'react';
import cl from './EducationLayout.module.css'
import cn from 'classnames'

const EducationLayout = ({ children, className, style }) => {
    return (
        <div className={cn(cl.EducationWrap, className)} style={style}>
            {children}
        </div>
    );
};

export default EducationLayout;