"use client"
import ListVertical from ".."
import styled from "styled-components"

export const ListVerticalStart = styled(ListVertical)` 
justify-content: flex-start;
    align-items: flex-start;
`
export const ListVerticalCenter = styled(ListVertical)` 
    justify-content: center;
    align-items: flex-start;
`
export const ListVertical_DullBlue = styled(ListVertical)` 
    background: var(--dullBlue);
   
`
export const ListVertical_LightBlue = styled(ListVertical)` 
    background: var(--lightBlue);
` 