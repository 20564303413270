"use client";

import { MotionA, MotionI } from "@ui/Links/DownloadCard/ui";
import { motion } from "framer-motion";

export function AnimMotionA({ children, ...props }) {
    return (
        <MotionA {...props}>
            {children}
        </MotionA>
    );
}
export function AnimMotionI({ children, ...props }) {
    return (
        <MotionI {...props}>
            {children}
        </MotionI>
    );
}
