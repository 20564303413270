import(/* webpackMode: "eager", webpackExports: ["AnimMotionA","AnimMotionI"] */ "/app/lib/Transition.jsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/public/images/logo_dump/orel_logo_1.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/logo.svg");
;
import(/* webpackMode: "eager", webpackExports: ["device_token"] */ "/app/src/app/globals/cookie.js");
;
import(/* webpackMode: "eager" */ "/app/src/shared/store/userStore.js");
;
import(/* webpackMode: "eager", webpackExports: ["BlueWrap","BlueWrapSBCenter"] */ "/app/src/shared/UI/Layouts/BlueWrap/BlueWrap.jsx");
;
import(/* webpackMode: "eager" */ "/app/src/shared/UI/Layouts/ContentHorizontal/Styled/ContentHorizontalStyled.jsx");
;
import(/* webpackMode: "eager" */ "/app/src/shared/UI/Layouts/EducationLayout/EducationLayout.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["Layout1440","Layout1200","LayoutFullWidth"] */ "/app/src/shared/UI/Layouts/LayoutsSized/ui.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/shared/UI/Layouts/ListVertical/ListVertical.jsx");
;
import(/* webpackMode: "eager" */ "/app/src/shared/UI/Layouts/ListVertical/styled/ListVerticalStyled.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/shared/UI/Layouts/MainWrap/MainWrap.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["MainWrapCenter"] */ "/app/src/shared/UI/Layouts/MainWrapCenter/ui.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/shared/UI/Layouts/PageTitleWrap/PageTitleWrap.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["BlockCards","Logo","HorizontalBlockWrap","Card","TitleBlock","List","ElementList","SizedSpan","ElemLeft","BoldSpan","ElemRightAlign","SupportCard","Elem","Label","BoldSpanUppercase","HorizontalBlockWrapElem","HorizontalBlockRequisites"] */ "/app/src/sheets/AboutProduct/ui.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/widgets/HeaderMain/HeaderMain.jsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/HeaderMain/HeaderMainStyled.jsx");
