"use client"
import styled from "styled-components";



export const ContentHorizontal = styled.section`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 25px;
    max-width: 1400px;
    justify-content: space-between;
    /* width: 100%; */
`
export const ContentHorisontalCenter = styled(ContentHorizontal)`
    justify-content: center;
`
export const ContentHorisontalSB = styled(ContentHorizontal)`
    justify-content: space-between;
`
export const ContentHorisontalStart = styled(ContentHorizontal)`
    justify-content: flex-start;
`