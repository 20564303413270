"use client"

import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import pageState from '@shared/store/pageState';

const PageTitleWrap = observer(({ pageTitle, className, children }) => {
    useEffect(() => {
        pageState.setPageTitle(pageTitle)
    }, [])

    return (
        <>
            {children}
        </>
    );
});

export default PageTitleWrap;